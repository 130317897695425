/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getProjectHealthStats } from "~/api";
import { Button } from "~/components";
import Loader from "~/components/UI/Loader";
import { COLORS } from "~/constants/values";
import { isSubscribedFor } from "~/utils/authHelper";

export const ProjectHealth = () => {
  const navigate = useNavigate();
  const { data: projectHealth, isLoading: healthLoading } = useQuery({
    queryKey: ["project health", "stats"],
    enabled: isSubscribedFor("projects"),
    queryFn: async () => {
      try {
        const data = await getProjectHealthStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const getProjectStat = (key: string) => {
    if (!projectHealth) return "";
    const [stat] = projectHealth.filter((st: any) => st.value == key);
    return stat && stat.count ? stat?.count : 0;
  };
  const series: ApexOptions["series"] = [
    {
      data: [
        {
          x: "Completed",
          y: getProjectStat("completed"),
          fillColor: COLORS.COMPLETED,
          meta: "completed",
        },
        {
          x: "On track",
          y: getProjectStat("on-track"),
          fillColor: COLORS.ON_TRACK,
          meta: "on-track",
        },
        {
          x: "Warning",
          y: getProjectStat("warning"),
          fillColor: COLORS.WARNING,
          meta: "warning",
        },
        {
          x: "At risk",
          y: getProjectStat("at-risk"),
          fillColor: COLORS.AT_RISK,
          meta: "at-risk",
        },
        {
          x: "Catching Up",
          y: getProjectStat("catching-up"),
          fillColor: COLORS.CATCHING_UP,
          meta: "catching-up",
        },
        {
          x: "Overdue",
          y: getProjectStat("overdue"),
          fillColor: COLORS.OVERDUE,
          meta: "overdue",
        },
        {
          x: "On Hold",
          y: getProjectStat("on-hold"),
          fillColor: COLORS.ON_HOLD,
          meta: "on-hold",
        },
      ],
    },
  ];
  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
      type: "bar",
      background: "#fff",
      events: {
        dataPointSelection(_, __, options) {
          navigate(
            `/app/projects?health=true&show=${
              options.w.config.series[0].data[options.dataPointIndex].meta
            }`
          );
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
      },
    },
    stroke: {
      show: false,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: ["#525866"],
          fontSize: "12px",
          fontFamily: "Inter, Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#525866"],
          fontSize: "12px",
          fontFamily: "Inter, Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
    },
  };
  return (
    <div className="bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm">
      <header className="w-full flex items-center justify-between border-b pb-4">
        <span className="font-medium">Project Health Summary</span>
        <Button label="Details" mono effect={() => navigate("/app/projects")} />
      </header>
      {healthLoading ? (
        <div className="py-10">
          <Loader big />
        </div>
      ) : (
        <div id="chart" className="max-h-[212px]">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={212}
          />
        </div>
      )}
    </div>
  );
};
