import { Link, Outlet } from "react-router-dom";

export const AuthLayout = () => {
  return (
    <div className=" w-full h-full relative lg:overflow-clip">
      <div className="mix-blend-plus-darker opacity-60 w-full h-full">
        <img
          src="/authbg.png"
          alt="bg image"
          className="hidden lg:block w-full opacity-[33%] h-full"
        />
      </div>
      <div className="w-full h-full px-5 lg:px-[50px] py-10 flex flex-col absolute inset-0">
        <Link to="/">
          <img src="/logo.svg" className="w-[115.02px]" />
        </Link>

        <div className="w-full h-full max-w-[1440px] mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
