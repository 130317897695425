/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActivateClient, DeactivateClient, DeleteClient } from "~/actions";
import {
  Button,
  Header,
  Kebab,
  Pagination,
  Pill,
  SearchBar,
  SearchDropdown,
  Table,
  TextPanes,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { useForm, usePagination, usePanes } from "~/hooks";
import { openModal } from "~/providers";
import { getClients, getClientStats } from "~/queries/clientQueries";
import { isActionAllowed } from "~/utils/authHelper";
import { getFormattedDate } from "~/utils/dateUtils";

export const ClientsPage = () => {
  const { handlePageChange } = usePagination();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("show") || "";
  const popup = openModal();
  const search = searchParams.get("search") || "";
  const filterBy = searchParams.get("filterBy") || "name";
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { show, handlePaneSwitch } = usePanes(status);
  const { formData, update: updateFilter } = useForm({
    initial: { filterBy },
  });
  const { data: clients, isLoading } = useQuery({
    queryKey: ["clients", { page, limit, status, search, ...formData }],
    enabled: isActionAllowed("list-clients"),
    queryFn: async () => {
      try {
        const data = await getClients({
          page,
          limit,
          "filter[status]": status,
          [`filter[${formData.filterBy}]`]: search,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { data: clientStats, isLoading: statsLoading } = useQuery({
    queryKey: ["clients", "stats"],
    enabled: isActionAllowed("list-clients"),
    queryFn: async () => {
      try {
        const data = await getClientStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const getStat = (key: string) => {
    if (!clientStats) return "";
    const [stat] = clientStats.filter((st: any) => st.value == key);
    return stat?.count || "0";
  };

  const getTotalCount = (array: any[]) => {
    return array?.reduce((total, obj) => {
      return total + parseInt(obj?.count, 10);
    }, 0);
  };

  const panes = [
    {
      id: "",
      label: "All",
      show: true,
      value: getTotalCount(clientStats),
    },
    { id: "active", label: "Active", show: true, value: getStat("active") },
    {
      id: "inactive",
      label: "Inactive",
      show: true,
      value: getStat("inactive"),
    },
  ];
  const colums: ColumnDef<Client>[] = [
    {
      accessorFn: (row) => row.name,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Company</span>,
      size: 26,
    },
    {
      accessorFn: (row) => row.email,
      id: "email",
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
      size: 26,
    },
    {
      id: "project",
      cell: ({ row }) => (
        <span className="text-center w-full block">
          {row.original?.projects?.length}
        </span>
      ),
      header: () => <span className="w-full text-center block">Projects</span>,
      size: 13,
    },
    {
      id: "date",
      cell: ({ row }) => (
        <span>{getFormattedDate(row.original.createdAt)}</span>
      ),
      header: () => <span>Created</span>,
      size: 17,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      // size: 9.9,
    },
    {
      accessorFn: (row) => row.email,
      id: "event",
      cell: ({ row }) => (
        <Kebab>
          <div className="flex flex-col w-[90px]">
            <button
              onClick={() => navigate(`${row.original.id}/edit`)}
              className="kebab-button"
            >
              Edit Client
            </button>
            {row.original.status == "active" && (
              <button
                onClick={() =>
                  popup({
                    component: (
                      <DeactivateClient
                        id={row.original.id}
                        name={row.original.name}
                      />
                    ),
                  })
                }
                className="kebab-button"
              >
                Deactivate
              </button>
            )}
            {row.original.status !== "active" && (
              <button
                onClick={() =>
                  popup({
                    component: (
                      <ActivateClient
                        id={row.original.id}
                        name={row.original.name}
                      />
                    ),
                  })
                }
                className="kebab-button"
              >
                Activate
              </button>
            )}
            <button
              onClick={() =>
                popup({
                  component: (
                    <DeleteClient
                      id={row.original.id}
                      name={row.original.name}
                    />
                  ),
                })
              }
              className="kebab-button"
            >
              Remove
            </button>
          </div>
        </Kebab>
      ),
      header: () => <span> </span>,
    },
  ];
  const searchFunction = (v: string) => {
    setSearchParams(() => ({
      search: v!,
      flterBy: formData.filterBy!,
      show: show!,
      page: "1",
      limit: "10",
    }));
  };
  const clearFunction = () => {
    setSearchParams(new URLSearchParams());
  };
  return (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row items-center justify-between">
        <Header title="Clients" subtext="Manage and organise your clients." />
        {isActionAllowed("add-client") && (
          <Button
            label="Add new client"
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                  fill="white"
                />
              </svg>
            }
            leftIcon
            effect={() => navigate("/app/clients/create")}
          />
        )}
      </div>
      <div className="flex flex-col mt-6 w-full h-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />
        <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4 gap-y-5">
          <div className="flex items-center gap-2 sm:flex-row flex-col">
            {" "}
            <SearchBar
              placeholder={`Search by ${formData.filterBy}`}
              initialValue={search}
              onClear={clearFunction}
              onSearch={searchFunction}
            />
            <SearchDropdown
              label="Filter By"
              name="filterBy"
              update={updateFilter}
              options={[
                { value: "name", label: "name" },
                { value: "email", label: "email" },
              ]}
              placeholder="All"
              value={filterBy}
            />
          </div>
          {isLoading || statsLoading ? (
            <Loader big />
          ) : clients?.data?.length > 0 ? (
            <div className="overflow-x-scroll lg:overflow-auto">
              <Table
                clickFunction={navigate}
                key="clients"
                cols={colums}
                rawData={clients?.data}
              />
            </div>
          ) : (
            <div className="mt-6 min-h-[400px] w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No {show} clients to show
                  </span>
                  {isActionAllowed("add-client") && (
                    <span className=" text-sm text-[#525866]">
                      You have not added any clients. Add a new client to get
                      started
                    </span>
                  )}
                </div>
                {isActionAllowed("add-client") && (
                  <Button
                    mono
                    label="Add new client"
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                          fill="black"
                        />
                      </svg>
                    }
                    leftIcon
                    effect={() => navigate("/app/clients/create")}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {clients?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={clients?.meta?.totalNumberOfPages}
            total={clients?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
