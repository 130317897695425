import { STORAGE_KEYS } from "~/constants/values";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isActionAllowed = (permission: string) => {
  const role = JSON.parse(localStorage.getItem(STORAGE_KEYS.ROLE)!) || {
    role: { name: "" },
  };
  if (
    role?.permissions?.find(
      (perm: Permission) => perm?.slug.toLowerCase() == permission.toLowerCase()
    )
  )
    return true;
  return false;
};
export const isSubscribedFor = (feature: string) => {
  const subscription = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.SUBSCRIPTION)!
  ) || {
    plan: { features: [] },
  };
  if (
    subscription?.plan?.features?.find(
      (feat: any) => feat?.slug.toLowerCase() == feature.toLowerCase()
    )
  )
    return true;
  return false;
};
