/* eslint-disable @typescript-eslint/no-explicit-any */
import { focusManager, useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createProjectRequest, getPublicWorkspace } from "~/api";
import {
  AnimatedLoader,
  Button,
  Group,
  PhoneTextInput,
  SelectElement,
  TextArea,
  TextInput,
} from "~/components";
import { useForm } from "~/hooks";
import { handleError, hasEmptyValues } from "~/utils/helpers";
export const CreateProjectRequestsPage = () => {
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate("/app");
  };
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(Date.now());
  const siteKey = import.meta.env.VITE_SITE_KEY;
  useEffect(() => {
    const interval = setInterval(() => {
      if (focusManager.isFocused()) {
        setRefreshReCaptcha(Date.now());
      }
    }, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  const { data: details, isLoading } = useQuery({
    queryKey: ["workspace details"],
    queryFn: async () => {
      try {
        const data = await getPublicWorkspace();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const getServiceTypes = () =>
    details?.serviceTypes?.map((st: string) => ({
      value: st,
      label: st,
    }));
  const { formData, update, setErrors, errors } = useForm({
    initial: {
      name: "",
      description: "",
      serviceType: "",
      availableDate: "",
      startDate: "",
      estimatedDuration: "",
    },
  });
  const {
    formData: clientData,
    update: updateClientData,
    setErrors: setClientErrors,
    errors: clientErrors,
  } = useForm({
    initial: {
      name: "",
      email: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
    },
  });
  const { formData: secondary, update: updateSecondary } = useForm({
    initial: {
      contactName2: "",
      contactEmail2: "",
      contactPhone2: "",
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      createProjectRequest({
        ...formData,
        startDate: formData.startDate
          ? dayjs(formData.startDate).format("YYYY-MM-DD")
          : formData.startDate,
        recaptchaResponse: captchaToken,
        client: {
          ...clientData,
          contactPhone: `+${clientData.contactPhone}`,

          ...(secondary.contactPhone2 && {
            contactPhone2: `+${secondary.contactPhone2}`,
          }),
          ...(secondary.contactEmail2 && {
            contactEmail2: secondary.contactEmail2,
          }),
          ...(secondary.contactName2 && {
            contactName2: secondary.contactName2,
          }),
        },
      }),
    ...{
      onSuccess() {
        toast.success(`Project request submitted successfully`);
        navigate("/requests/thanks");
      },
      onError(error) {
        const handledError = handleError(error);
        if (handledError.type === "validation") {
          setErrors(handledError.messages);
          setClientErrors(handledError.messages.client);
        }
        setRefreshReCaptcha(Date.now());
      },
    },
  });
  return isPending || isLoading ? (
    <AnimatedLoader />
  ) : (
    <div className="mt-8 lg:mt-0 remove-scroll w-full h-full flex flex-col items-center">
      <h1 className="text-2xl font-medium">Submit a project request</h1>
      <div className="flex flex-col w-full text-[#0A0D14] overflow-clip h-full p-2 lg:p-8 mt-10">
        <div className="flex flex-col items-center w-full overflow-scroll remove-scroll pb-10">
          <div className="flex flex-col gap-y-6 w-full items-center mt-6 max-w-[728px] mx-auto">
            <Group key="project details">
              <div className="flex flex-col gap-y-5 w-full">
                <h3 className="font-medium">Project Details</h3>
                <div className="p-5 rounded-lg bg-white mix-blend-soft-light bg-opacity-60 border flex flex-col w-full gap-y-5">
                  <TextInput
                    label="Project name"
                    value={formData.name}
                    error={errors.name}
                    name="name"
                    placeholder="Enter project name"
                    autoFocus
                    handleInputChange={update}
                  />
                  <TextArea
                    label="Project description"
                    value={formData.description}
                    name="description"
                    placeholder="Enter description..."
                    handleInputChange={update}
                  />

                  <SelectElement
                    label="Service Type"
                    value={formData.serviceType}
                    error={errors.serviceType}
                    name="serviceType"
                    placeholder="Enter Service Type"
                    onChange={update}
                    options={getServiceTypes()}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 gap-x-5">
                    <TextInput
                      label="Estimated Duration (in days)"
                      value={formData.estimatedDuration}
                      error={errors.estimatedDuration}
                      name="estimatedDuration"
                      type="number"
                      handleInputChange={update}
                    />
                    <TextInput
                      label="Available time for a call"
                      value={formData.availableDate}
                      error={errors.availableDate}
                      name="availableDate"
                      type="date"
                      handleInputChange={update}
                      {...{ min: dayjs().format("YYYY-MM-DD") }}
                    />
                  </div>
                  <TextInput
                    label="Proposed Start Date"
                    value={formData.startDate}
                    error={errors.startDate}
                    name="startDate"
                    type="date"
                    handleInputChange={update}
                    {...{
                      min: dayjs(formData.availableDate)
                        .add(1, "day")
                        .format("YYYY-MM-DD"),
                    }}
                  />
                </div>
              </div>
            </Group>
            <Group key="primary contact">
              <div className="flex flex-col gap-y-5 w-full">
                <h3 className="font-medium">Primary contact details</h3>
                <div className="p-5 rounded-lg bg-white border bg-opacity-40 flex flex-col w-full gap-y-5">
                  <TextInput
                    error={clientErrors?.name}
                    value={clientData.name}
                    required
                    name="name"
                    label="Company Name"
                    placeholder="Acme Inc"
                    handleInputChange={updateClientData}
                  />
                  <TextInput
                    error={clientErrors?.email}
                    value={clientData.email}
                    required
                    name="email"
                    label="Company Email"
                    placeholder="mail@acme.com"
                    handleInputChange={updateClientData}
                  />
                  <TextInput
                    error={clientErrors?.contactName}
                    value={clientData.contactName}
                    required
                    name="contactName"
                    label="Full Name"
                    placeholder="John Doe"
                    handleInputChange={updateClientData}
                  />
                  <TextInput
                    error={clientErrors?.contactEmail}
                    value={clientData.contactEmail}
                    required
                    name="contactEmail"
                    type="email"
                    label="Email Address"
                    placeholder="john@acmee.com"
                    handleInputChange={updateClientData}
                  />
                  <PhoneTextInput
                    error={clientErrors?.contactPhone}
                    value={clientData.contactPhone}
                    name="contactPhone"
                    onChange={updateClientData}
                  />
                </div>
              </div>
            </Group>
            <Group key="secondary contact">
              <div className="flex flex-col gap-y-5 w-full">
                <h3 className="font-medium relative z-[-1]">
                  Secondary contact details (optional)
                </h3>
                <div className="p-5 rounded-lg bg-white mix-blend-soft-light bg-opacity-60 border flex flex-col w-full gap-y-5">
                  <TextInput
                    value={secondary.contactName2}
                    name="contactName2"
                    label="Full Name"
                    placeholder="Jane Doe"
                    handleInputChange={updateSecondary}
                  />
                  <TextInput
                    value={secondary.contactEmail2}
                    name="contactEmail2"
                    label="Email Address"
                    placeholder="jane@acme.com"
                    handleInputChange={updateSecondary}
                  />
                  <PhoneTextInput
                    value={secondary.contactPhone2}
                    name="contactPhone2"
                    onChange={updateSecondary}
                  />
                </div>
              </div>
            </Group>
            <div className="ml-auto">
              <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                <GoogleReCaptcha
                  onVerify={setCaptchaToken}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </GoogleReCaptchaProvider>
            </div>
            <div className="py-5 flex justify-center lg:justify-end items-center gap-x-3 w-full">
              <Button mono label="Cancel" onClick={handleCancelClick} />
              <Button
                label="Submit Request"
                effect={mutate}
                disabled={
                  hasEmptyValues(formData) ||
                  hasEmptyValues(clientData) ||
                  !captchaToken
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
