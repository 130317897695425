/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header } from "~/components";
import { Choice } from "./components/Choice";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPlans } from "~/queries/billingsQueries";
import toast from "react-hot-toast";
import Loader from "~/components/UI/Loader";
import { PlanCard } from "./components/PlanCard";
import { verifySubscription } from "~/mutations/billingsMutations";
import { useLocation } from "~/hooks";
import { useAuth } from "~/providers";

export const Plans = () => {
  const { workspace } = useAuth();
  const [choice, setChoice] = useState("Monthly");
  const [reference, setReference] = useState<any>();
  const { location, finding } = useLocation();
  const { data: plans, isLoading } = useQuery<ListResponse<Plan>>({
    queryKey: ["plans", "active"],
    queryFn: async () => {
      try {
        const data = await getPlans({
          "filter[status]": "active",
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { isLoading: verifying } = useQuery({
    enabled: Boolean(reference?.reference),
    queryKey: ["verificatoin"],
    queryFn: async () => {
      try {
        const data = await verifySubscription(
          reference?.id,
          reference?.reference
        );
        window.location.assign("/app");
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  return (
    <div className="w-full flex flex-col gap-y-6 relative h-full overflow-x-scroll lg:overflow-auto">
      <Header title="Plans" subtext="Do more by upgrading to a paid plan." />
      <Choice choice={choice} setChoice={setChoice} />
      {isLoading || finding ? (
        <Loader big />
      ) : (
        <div className="flex flex-col lg:flex-row items-center lg:items-start h-full gap-5 ">
          {plans?.data
            .filter(
              (plan) => plan.interval.toLowerCase() == choice.toLowerCase()
            )
            .map((plan, index) => (
              <PlanCard
                index={index}
                setReference={setReference}
                location={location!}
                current={workspace?.subscription}
                data={plan}
                key={plan?.id}
                time={choice == "Monthly" ? "month" : "year"}
              />
            ))}
        </div>
      )}
      {verifying && (
        <div className="absolute inset-0 w-full h-full">
          <Loader big />
        </div>
      )}
    </div>
  );
};
