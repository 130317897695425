export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export enum COLORS {
  ON_TRACK = "#23C389",
  WARNING = "#F2AE40",
  AT_RISK = "#DF1C41",
  CATCHING_UP = "#375DFB",
  OVERDUE = "#E32227",
  ON_HOLD = "#868C98",
  COMPLETED = "#35B9E9",
}

export enum STORAGE_KEYS {
  AUTH_TOKEN = "who_auth_token",
  AUTH_REDIRECT = "who_auth_redirect",
  USER = "who_user",
  TOKEN_DATA = "who_token_data",
  SUBSCRIPTION = "who_sub",
  ROLE = "who_r",
}
