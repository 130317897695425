/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { VerifyCode } from "~/actions";
import Loader from "~/components/UI/Loader";
import { STORAGE_KEYS } from "~/constants/values";
import { authorizeProvider } from "~/mutations/authMutations";
import { openModal, useAuth } from "~/providers";

export const Complete = () => {
  const { setStoredToken } = useAuth();
  const popup = openModal();

  const navigate = useNavigate();
  const code = useSearchParams()[0].get("code") || "";
  const { provider } = useParams();
  const { mutate, isPending } = useMutation({
    mutationFn: async () =>
      authorizeProvider(provider!, {
        code,
        token: localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN),
      }),
    onSuccess(data) {
      setStoredToken(data);
      const redirect = localStorage.getItem(STORAGE_KEYS.AUTH_REDIRECT);
      navigate(redirect || "/choose");
      localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
      localStorage.removeItem(STORAGE_KEYS.AUTH_REDIRECT);
    },
    onError(error: any) {
      localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
      if (error.status == 412) {
        popup({ component: <VerifyCode token={error.data.data.token} /> });
      } else {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  return isPending ? <Loader big /> : <div>Complete</div>;
};
