/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { ViewProjectRequest } from "~/actions";
import { getProjectStats } from "~/api";
import {
  CopyButton,
  Header,
  Pagination,
  Pill,
  Table,
  TextPanes,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { usePagination, usePanes } from "~/hooks";
import { openSideModal } from "~/providers";
import { getProjectRequests } from "~/queries/projectQueries";
import { getFormattedDate } from "~/utils/dateUtils";

export const RequestsPage = () => {
  const { handlePageChange } = usePagination();
  const requestUrl = new URL(window.origin).hostname + "/requests/create";
  const slideIn = openSideModal();
  const searchParams = useSearchParams()[0];
  const active = searchParams.get("active") || "pending";
  const { show, handlePaneSwitch } = usePanes(active);
  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { data: requests, isLoading } = useQuery({
    queryKey: ["requests", { page, limit, show }],
    queryFn: async () => {
      try {
        const data = await getProjectRequests({
          page,
          limit,
          "filter[status]": show,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { data: projectStats, isLoading: statsLoading } = useQuery({
    queryKey: ["projects", "stats"],
    queryFn: async () => {
      try {
        const data = await getProjectStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const getStat = (key: string) => {
    if (!projectStats) return "";
    const [stat] = projectStats?.filter((st: any) => st.value == key);
    return stat?.count || 0;
  };

  const panes = [
    { id: "pending", label: "Pending", show: true, value: getStat("pending") },

    {
      id: "rejected",
      label: "Rejected",
      show: true,
      value: getStat("rejected"),
    },
  ];
  const colums: ColumnDef<Project>[] = [
    {
      accessorFn: (row) => row.client.name,
      id: "email",
      cell: (info) => info.getValue(),
      header: () => <span>Client</span>,
      size: 16,
    },
    {
      id: "project",
      cell: ({ row }) => <span>{row.original.serviceType}</span>,
      header: () => <span>Service Category</span>,
      size: 16,
    },
    {
      id: "date",
      cell: ({ row }) => (
        <span className=" block text-center">
          {`${row.original.estimatedDuration} days`}
        </span>
      ),
      header: () => <span className=" block text-center">Est. Duration</span>,
      size: 11,
    },
    {
      id: "health",
      cell: ({ row }) => (
        <span>{getFormattedDate(row.original.availableDate)}</span>
      ),
      header: () => <span>Availability</span>,
      size: 12,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 11,
    },
    // {
    //   id: 'event',
    //   cell: ({ row }) => (
    //     <Kebab>
    //       <div className='flex flex-col w-[100px]'></div>
    //     </Kebab>
    //   ),
    //   header: () => <span> </span>,
    // },
  ];
  const handleClick = (data: Project) => {
    slideIn({ component: <ViewProjectRequest id={data.id} /> });
  };
  return statsLoading ? (
    <Loader big />
  ) : (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex flex-col gap-2 lg:flex-row items-center justify-between">
        <Header title="Requests" subtext="Manage all your project requests." />
        <div className="flex flex-col gap-2 lg:flex-row items-center gap-x-3">
          <p className="text-sm w-[200px] lg:w-auto break-all lg:whitespace-nowrap  text-[#868C98]">
            {requestUrl}
          </p>
          <CopyButton value={requestUrl} />
        </div>
      </div>

      <div className="flex flex-col mt-6 w-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />
        <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4">
          {isLoading ? (
            <Loader big />
          ) : requests?.data?.length > 0 ? (
            <div className="overflow-x-scroll lg:overflow-auto">
              <Table
                clickFunction={handleClick}
                useData
                key="requests"
                cols={colums}
                rawData={requests?.data}
              />
            </div>
          ) : (
            <div className="mt-6 min-h-[400px] w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No {show} requests to show
                  </span>
                  <span className=" text-sm text-[#525866]">
                    You have not received any project requests.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {requests?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={requests?.meta?.totalNumberOfPages}
            total={requests?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
