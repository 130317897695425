/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormEvent, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  PasswordHandler,
  PasswordInput,
  TextInput,
  Group,
} from "~/components";
import { useForm } from "~/hooks";
import { completeSetup } from "~/mutations/authMutations";
import { useAuth } from "~/providers";
import { AuthScreen } from "~/screens";
import { checkIfValid } from "~/utils/passwordHelpers";
import { getAuthProviders } from "~/queries/authQueries";
import { SignupWithGoogle } from "./components/SignupWithGoogle";
import { SignupWithMicrosoft } from "./components/SignupWithMicrosoft";
import { SignupWithLinkedin } from "./components/SignupWithLinkedin";
import Loader from "~/components/UI/Loader";
import { STORAGE_KEYS } from "~/constants/values";

export const ProfileSetupPage = () => {
  const navigate = useNavigate();
  const { setStoredToken } = useAuth();
  const searchParams = useSearchParams()[0];
  const token = searchParams.get("token") || "";
  const redirect = searchParams.get("redirect") || "";

  useEffect(() => {
    if (redirect) {
      localStorage.setItem(STORAGE_KEYS.AUTH_REDIRECT, redirect);
    } else {
      localStorage.removeItem(STORAGE_KEYS.AUTH_REDIRECT);
    }
  }, [redirect]);

  const { formData, update } = useForm({
    initial: { firstName: "", lastName: "", password: "", confirmPassword: "" },
  });
  const { data: providers, isLoading } = useQuery<
    { provider: string; url: string }[]
  >({
    queryKey: ["providers"],
    queryFn: async () => {
      try {
        const data = await getAuthProviders();
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: () => completeSetup({ ...formData }, token),
    ...{
      onSuccess(data: any) {
        setStoredToken(data);
        const redirect = localStorage.getItem(STORAGE_KEYS.AUTH_REDIRECT);
        navigate(redirect || "/choose");
        localStorage.removeItem(STORAGE_KEYS.AUTH_REDIRECT);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });

  const handleMutate = (e: FormEvent) => {
    e.preventDefault();
    mutate();
  };

  const handleOauth = () => {
    localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, token);
  };

  const getProviderUrl = (provider: "microsoft" | "linkedin" | "google") => {
    if (!providers || providers.length < 1) return "";
    const [url] = providers!.filter((pr) => pr.provider == provider);
    return url.url;
  };

  return (
    <AuthScreen icon="/auth/id.svg">
      <div className="flex flex-col w-full">
        <section className="flex flex-col">
          <h1 className="text-[#0A0D14] text-[32px] font-medium">
            Almost there...
          </h1>
          <span className="text-sm text-[#525866]">
            We just need a few more things to finish setting up your profile
          </span>
        </section>
        <Group key="oauth">
          <section className="mt-8 flex flex-col w-full gap-y-2">
            {isLoading ? (
              <div className="w-full flex justify-center h-16">
                <Loader />
              </div>
            ) : (
              <>
                <SignupWithGoogle
                  url={getProviderUrl("google")}
                  onClick={handleOauth}
                />
                <SignupWithMicrosoft
                  url={getProviderUrl("microsoft")}
                  onClick={handleOauth}
                />
                <SignupWithLinkedin
                  url={getProviderUrl("linkedin")}
                  onClick={handleOauth}
                />
              </>
            )}
          </section>
        </Group>
        <span className="mt-4 block text-center text-sm font-medium">Or</span>
        <form onSubmit={handleMutate} className="mt-8 flex flex-col gap-y-4">
          <TextInput
            name="firstName"
            value={formData.firstName}
            label="First Name"
            autoFocus
            placeholder="Enter your first name"
            handleInputChange={update}
          />
          <TextInput
            name="lastName"
            value={formData.lastName}
            label="Last Name"
            placeholder="Enter your last name"
            handleInputChange={update}
          />
          <div className="flex flex-col">
            <PasswordInput
              name="password"
              value={formData.password}
              label="Password"
              placeholder="• • • • • • • • • • "
              handleInputChange={update}
            />
            <PasswordHandler
              password={formData.password!}
              confirmPassword={formData.confirmPassword}
            />
          </div>
          <PasswordInput
            value={formData.confirmPassword}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="• • • • • • • • • • "
            handleInputChange={update}
          />
          <Button
            loading={isPending}
            type="submit"
            label="Continue"
            disabled={
              !formData.firstName ||
              !formData.lastName ||
              !checkIfValid(formData.password!, formData.confirmPassword!)
            }
          />
        </form>
      </div>
    </AuthScreen>
  );
};
