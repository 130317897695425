/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Header,
  Kebab,
  Pagination,
  Pill,
  SearchBar,
  Table,
  TextPanes,
} from "~/components";
import { openModal, openSideModal } from "~/providers";
import { AddRole } from "./AddRole";
import { useQuery } from "@tanstack/react-query";
import { getRoles, getRoleStats } from "~/queries/roleQueries";
import toast from "react-hot-toast";
import Loader from "~/components/UI/Loader";
import { ColumnDef } from "@tanstack/react-table";
import { DeleteRole } from "./DeleteRole";
import { ActivateRole } from "./ActivateRole";
import { DeactivateRole } from "./DeactivateRole";
import { EditRole } from "./EditRole";
import { useState } from "react";
import { isActionAllowed } from "~/utils/authHelper";

export const Permissions = () => {
  const popup = openModal();
  const slide = openSideModal();
  const [active, setActive] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { data: roles, isLoading } = useQuery({
    enabled: isActionAllowed("list-roles"),
    queryKey: ["roles", { active, search, page }],
    queryFn: async () => {
      try {
        const data = await getRoles({
          "filter[name]": search,
          "filter[status]": active,
          page,
          limit: 10,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { data: roleStats, isLoading: statsLoading } = useQuery({
    enabled: isActionAllowed("list-roles"),
    queryKey: ["roles", "stats"],
    queryFn: async () => {
      try {
        const data = await getRoleStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const getStat = (key: string) => {
    if (!roleStats) return "";
    const [stat] = roleStats.filter((st: any) => st.value == key);
    return stat?.count || "0";
  };

  const getTotalCount = (array: any[]) => {
    return array?.reduce((total, obj) => {
      return total + parseInt(obj?.count, 10);
    }, 0);
  };
  const panes = [
    {
      id: "",
      label: "All",
      show: true,
      value: getTotalCount(roleStats),
    },
    { id: "active", label: "Active", show: true, value: getStat("active") },
    {
      id: "inactive",
      label: "Inactive",
      show: true,
      value: getStat("inactive"),
    },
  ];

  const handleClick = (
    data: Role,
    action: "delete" | "activate" | "deactivate" | "edit"
  ) => {
    action == "delete" && popup({ component: <DeleteRole data={data} /> });
    action == "activate" && popup({ component: <ActivateRole data={data} /> });
    action == "deactivate" &&
      popup({ component: <DeactivateRole data={data} /> });
    action == "edit" && slide({ component: <EditRole data={data} /> });
  };

  const colums: ColumnDef<Role>[] = [
    {
      accessorFn: (row) => row.name,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Role Name</span>,
      size: 22,
    },
    {
      id: "description",
      cell: ({ row }) => <span>{row.original.description}</span>,
      header: () => <span>Description</span>,
      size: 41,
    },
    {
      id: "permissions",
      cell: ({ row }) => row.original.permissions.length,
      header: () => <span>Permission</span>,
      size: 15,
    },
    {
      id: "stattus",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 14,
    },
    {
      id: "action",
      cell: ({ row }) => (
        <Kebab>
          <div className="min-w-[158px] w-full flex flex-col items-start">
            {row.original.status == "active" &&
              row.original.workspaceId !== null && (
                <button
                  onClick={() => handleClick(row.original, "deactivate")}
                  className="kebab-button"
                >
                  Deactivate Role
                </button>
              )}
            {row.original.status == "inactive" &&
              row.original.workspaceId !== null && (
                <button
                  onClick={() => handleClick(row.original, "activate")}
                  className="kebab-button"
                >
                  Activate Role
                </button>
              )}
            {row.original.workspaceId !== null && (
              <button
                onClick={() => handleClick(row.original, "delete")}
                className="kebab-button"
              >
                Delete Role
              </button>
            )}
            <button
              onClick={() => handleClick(row.original, "edit")}
              className="kebab-button"
            >
              {row.original.workspaceId !== null ? "Edit Role" : "View Role"}
            </button>
          </div>
        </Kebab>
      ),
      header: () => <span> </span>,
      size: 8,
    },
  ];

  return (
    <div className="w-full flex flex-col h-full">
      <div className="flex flex-col lg:flex-row lg:items-center w-full justify-between">
        <Header
          title="Roles and Permissions"
          subtext="Manage your workspace roles and permissions."
        />
        <Button
          label="Add role"
          icon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                fill="white"
              />
            </svg>
          }
          leftIcon
          effect={() => slide({ component: <AddRole /> })}
        />
      </div>
      <div className="flex flex-col mt-6 w-full">
        <TextPanes
          panes={panes}
          active={active}
          handleChange={(id) => {
            setActive(id);
            setPage(1);
          }}
        />
        <div className="mt-6 bg-white w-full h-full rounded-xl flex flex-col p-4 gap-y-5">
          <div className="flex items-center">
            {" "}
            <SearchBar
              placeholder="Search..."
              initialValue={search}
              onClear={() => setSearch("")}
              onSearch={(v: string) => setSearch(v)}
            />
          </div>
          {isLoading || statsLoading ? (
            <div className="py-20">
              <Loader big />
            </div>
          ) : roles?.data.length > 0 ? (
            <div className="overflow-x-scroll lg:overflow-auto">
              <Table key="roles" cols={colums} rawData={roles.data} />
            </div>
          ) : (
            <div className="mt-6 min-h-[400px] w-full h-full rounded-xl flex flex-col items-center justify-center  text-center py-20">
              <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                <img src="/empty.svg" className="w-[100px]" />
                <div className="flex flex-col items-center gap-y-2">
                  <span className=" text-sm text-[#0A0D14]">
                    No permissions to show
                  </span>
                  {isActionAllowed("create-role") && (
                    <span className=" text-sm text-[#525866]">
                      You have not added any roles. Add a new role to get
                      started
                    </span>
                  )}
                </div>
                <Button
                  mono
                  label="Add role"
                  icon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                        fill="black"
                      />
                    </svg>
                  }
                  leftIcon
                  effect={() => slide({ component: <AddRole /> })}
                />
              </div>
            </div>
          )}
        </div>
        {roles?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={10}
            pages={roles?.meta?.totalNumberOfPages}
            total={roles?.meta?.totalNumberOfRecords}
            handlePageChange={(e) => {
              setPage(e.selected + 1);
            }}
          />
        )}
      </div>
    </div>
  );
};
