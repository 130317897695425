/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button, Pill, Table } from "~/components";
import Loader from "~/components/UI/Loader";
import { TableAvatar } from "~/components/UI/TableAvatar";
import { getProjectUsers } from "~/queries/projectQueries";
import { isActionAllowed } from "~/utils/authHelper";
import { InviteMember } from "../../Invite";
import { openModal } from "~/providers";

export const Team = () => {
  const { id } = useParams();
  const qc = useQueryClient();
  const popup = openModal();

  const { isLoading, data: members } = useQuery({
    queryKey: ["project", id, "members"],
    queryFn: async () => {
      try {
        const data = await getProjectUsers(id);
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const colums: ColumnDef<User>[] = [
    {
      id: "name",
      cell: ({ row }) => (
        <TableAvatar
          first_name={row.original.profile.firstName}
          last_name={row.original.profile.lastName}
        />
      ),
      header: () => <span>Member Name</span>,
      size: 31,
    },
    {
      id: "email",
      cell: ({ row }) => (
        <span className="text-left ">{row.original.email}</span>
      ),
      header: () => <span>Email Address</span>,
      size: 31,
    },

    {
      id: "stattus",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 14,
    },
  ];
  return (
    <div className="  bg-white w-full rounded-xl min-h-[400px]">
      {isLoading ? (
        <Loader big />
      ) : members?.length > 0 ? (
        <div className=" bg-white w-full h-full rounded-xl flex flex-col min-h-[400px]">
          <div className="p-4 flex flex-col gap-y-5">
            <div className="flex items-center gap-x-2">
              {" "}
              <span className="ml-auto">
                {isActionAllowed("add-team-member") && (
                  <Button
                    effect={() =>
                      popup({
                        component: (
                          <InviteMember
                            close={(userId) => {
                              if (userId) {
                                qc.invalidateQueries({
                                  queryKey: ["project", id, "members"],
                                });
                              }
                            }}
                          />
                        ),
                      })
                    }
                    label="Invite Member"
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                          fill="white"
                        />
                      </svg>
                    }
                    leftIcon
                  />
                )}
              </span>
            </div>
            <div className="overflow-x-scroll overflow-x-visible lg:overflow-auto">
              <Table key="roles" cols={colums} rawData={members} />
            </div>
          </div>
        </div>
      ) : (
        <div className="  bg-white w-full h-full rounded-xl flex flex-col items-center justify-center  text-center">
          <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
            <img src="/empty.svg" className="w-[100px]" />
            <div className="flex flex-col items-center gap-y-2">
              <span className=" text-sm text-[#0A0D14]">No team members</span>
              <span className=" text-sm text-[#525866]">
                No team members attached to this project
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
