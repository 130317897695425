/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../config/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const baseUrl =
  import.meta.env.VITE_BASE_URL || "https://platform.api.wholistic.work";

export const getProjectIntegrations = async (id: string, params?: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}/integrations`,
    params,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const addProjectIntegrations = async (id: any, data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${id}/integrations`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const deleteProjectIntegration = async (
  id: any,
  projectIntegrationId: string
) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/projects/${id}/integrations/${projectIntegrationId}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const authorizeProjectIntegrations = async (id: any, data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/integrations/${id}/auth`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const removeIntegrationAuthorization = async (id: any) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/integrations/${id}/auth`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const createProjectRequest = async (data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/requests`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const acceptProjectRequest = async (id: any) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/projects/requests/${id}/accept`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const rejectProjectRequest = async (id: any, reason?: string) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/projects/requests/${id}/reject`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
    data: { reason },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

//comments
export const getProjectComments = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}/comments`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const addProjectComments = async (id: any, data?: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${id}/comments`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const deleteProjectComment = async (projectId: any, commentId?: any) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/projects/${projectId}/comments/${commentId}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

//reports
export const getProjectReports = async (id: any, params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${id}/reports`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getReports = async (params: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/reports`,
    headers,
    params,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const getReportsStats = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/reports/stats`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const addProjectReport = async (id: any, data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${id}/reports`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const updateProjectReport = async (
  id: any,
  reportId: any,
  data: any
) => {
  const config = {
    method: "PATCH",
    url: `${baseUrl}/projects/${id}/reports/${reportId}`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const sendProjectReport = async (id: any, reportId: any, data: any) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${id}/reports/${reportId}/send`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const deleteProjectReport = async (projectId: any, reportId: any) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/projects/${projectId}/reports/${reportId}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getProjectReport = async (projectId: any, reportId: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${projectId}/reports/${reportId}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const exportProjectReport = async (projectId: any, reportId: any) => {
  const config: any = {
    method: "POST",
    url: `${baseUrl}/projects/${projectId}/reports/${reportId}/export`,
    headers: { ...headers, "Content-Type": "application/pdf" },
    withCredentials: true,
    withXSRFToken: true,
    responseType: "arraybuffer",
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getReporttComment = async (projectId: any, reportId: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/projects/${projectId}/reports/${reportId}/comments`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const addReporttComment = async (
  projectId: any,
  reportId: any,
  data?: any
) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${projectId}/reports/${reportId}/comments`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const deleteReporttComment = async (
  projectId: any,
  reportId: any,
  commentId?: any
) => {
  const config = {
    method: "DELETE",
    url: `${baseUrl}/projects/${projectId}/reports/${reportId}/comments/${commentId}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getReportTemplates = async () => {
  const config = {
    method: "GET",
    url: `${baseUrl}/report-templates`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
export const getReportTemplate = async (id: any) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/report-templates/${id}`,
    headers,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const addProjectQualityResource = async (
  projectId: any,
  data: any
  // projectIntegrationId: string
) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${projectId}/quality`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export const generateQualityResults = async (
  projectId: any,
  data: any
  // projectIntegrationId: string
) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/projects/${projectId}/quality`,
    headers,
    data,
    withCredentials: true,
    withXSRFToken: true,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};
