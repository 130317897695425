import {
  AuditLogsPage,
  ClientPage,
  ClientsPage,
  CreateClientPage,
  CreateProjectPage,
  CreateSubscriptionPage,
  DashboardPage,
  EditClients,
  EditProjectPage,
  EditReportPage,
  EditSubscriptionPage,
  OrganizationPage,
  OrganizationsPage,
  PreviewReportPage,
  ProjectPage,
  ProjectsPage,
  RequestsPage,
  SubscriptionPage,
  PlansPage,
  TransactionPage,
  TransactionsPage,
} from "~/pages";
import { AppLayout } from "../layouts";
import { ErrorPage } from "../pages/error/index";
import { NotificationsPage } from "~/pages/organization/inbox-pages";
import {
  Templates,
  Template,
  CreateTemplate,
  // PreviewHtml,
} from "~/actions/Settings/settings/setting";
// import { ReportsPage } from "~/pages/organization/report-pages/Reports";
// import { TransactionPage } from "~/pages/organization/template-pages/Transaction";

export const OrganizationRoutes = [
  {
    path: "/app",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <DashboardPage />, errorElement: "" },

      { path: "transactions", element: <TransactionsPage />, errorElement: "" },
      {
        path: "transactions/:id",
        element: <TransactionPage />,
        errorElement: "",
      },
      {
        path: "organizations",
        element: <OrganizationsPage />,
        errorElement: "",
      },
      {
        path: "organizations/:id",
        element: <OrganizationPage />,
        errorElement: "",
      },
      // WIP
      {
        path: "templates",
        element: <Templates />,
        errorElement: "",
      },
      {
        path: "templates/:id",
        element: <Template />,
        errorElement: "",
      },
      {
        path: "templates/create",
        element: <CreateTemplate />,
        errorElement: "",
      },
      {
        path: "subscriptions",
        element: <PlansPage />,
        errorElement: "",
      },
      {
        path: "subscriptions/:id",
        element: <SubscriptionPage />,
        errorElement: "",
      },
      {
        path: "subscriptions/:id/edit",
        element: <EditSubscriptionPage />,
        errorElement: "",
      },
      {
        path: "subscriptions/create",
        element: <CreateSubscriptionPage />,
        errorElement: "",
        exact: true,
      },
      { path: "clients", element: <ClientsPage />, errorElement: "" },
      {
        path: "clients/create",
        element: <CreateClientPage />,
        errorElement: "",
        exact: true,
      },
      { path: "clients/:id", element: <ClientPage />, errorElement: "" },
      { path: "clients/:id/edit", element: <EditClients />, errorElement: "" },
      { path: "projects", element: <ProjectsPage />, errorElement: "" },
      { path: "projects/:id", element: <ProjectPage />, errorElement: "" },
      // { path: "reports", element: <ReportsPage />, errorElement: "" },
      {
        path: "projects/:id/reports/:reportId",
        element: <EditReportPage />,
        errorElement: "",
      },
      {
        path: "projects/:id/reports/:reportId/preview",
        element: <PreviewReportPage />,
        errorElement: "",
      },

      {
        path: "projects/:id/edit",
        element: <EditProjectPage />,
        errorElement: "",
      },
      {
        path: "projects/create",
        element: <CreateProjectPage />,
        errorElement: "",
      },
      { path: "requests", element: <RequestsPage />, errorElement: "" },
      { path: "log", element: <AuditLogsPage />, errorElement: "" },
      { path: "inbox", element: <NotificationsPage />, errorElement: "" },
    ],
  },
];
