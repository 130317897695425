import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../Button";

interface SearchBarProps {
  placeholder?: string;
  initialValue?: string;
  onSearch: (value: string) => void;
  onClear?: () => void;
  useUrlParams?: boolean;
  searchParamName?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search",
  initialValue = "",
  onSearch,
  onClear,
  useUrlParams = false,
  searchParamName = "search",
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [localValue, setLocalValue] = useState(initialValue);

  useEffect(() => {
    if (useUrlParams) {
      const paramValue = searchParams.get(searchParamName) || "";
      setLocalValue(paramValue);
    }
  }, [useUrlParams, searchParams, searchParamName]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    if (useUrlParams) {
      if (newValue) {
        searchParams.set(searchParamName, newValue);
      } else {
        searchParams.delete(searchParamName);
      }
      setSearchParams(searchParams);
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(localValue);
  };

  const handleClear = () => {
    setLocalValue("");
    if (useUrlParams) {
      searchParams.delete(searchParamName);
      setSearchParams(searchParams);
    }
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className="flex flex-col gap-2 lg:flex-row items-center sm:w-auto w-full">
      <form
        className="flex items-center py-[6px] sm:max-w-[300px] w-full max-w-auto px-2 gap-x-2 border border-[#E2E4E9] rounded-lg"
        onSubmit={handleSearch}
      >
        <label htmlFor="search">
          <SearchIcon />
        </label>
        <input
          id="search"
          name="search"
          className="text-sm outline-none flex-grow"
          placeholder={placeholder}
          value={localValue}
          onChange={handleInputChange}
        />
      </form>
      {localValue && (
        <Button type="button" label="Clear" mono effect={handleClear} />
      )}
    </div>
  );
};

const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25 2.5C12.976 2.5 16 5.524 16 9.25C16 12.976 12.976 16 9.25 16C5.524 16 2.5 12.976 2.5 9.25C2.5 5.524 5.524 2.5 9.25 2.5ZM9.25 14.5C12.1502 14.5 14.5 12.1502 14.5 9.25C14.5 6.349 12.1502 4 9.25 4C6.349 4 4 6.349 4 9.25C4 12.1502 6.349 14.5 9.25 14.5ZM15.6137 14.5532L17.7355 16.6742L16.6742 17.7355L14.5532 15.6137L15.6137 14.5532Z"
      fill="#868C98"
    />
  </svg>
);
