/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ExpandableButton,
  Group,
  Pill,
  ProgressBar,
  TextPanes,
  Tracker,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { usePanes } from "~/hooks";
import { openModal } from "~/providers";
import { getProject } from "~/queries/projectQueries";
import { getFormattedDate } from "~/utils/dateUtils";
// import { getFullMoney } from '~/utils/formatAmount';
import { Documents } from "./panes/Documents";
import { Tasks } from "./panes/Tasks";
import { Communication } from "./panes/Communication";
import { Email } from "./panes/Email";
import { Reports } from "./panes/Reports";
import { Team } from "./panes/Team";
import { QualityResults } from "./panes/Quality";
import { DeleteProject, Settings } from "~/actions";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Connections } from "./sidebar/Connections";
import { ProjectChat } from "./sidebar/Chat";
import { getIntegrations } from "~/queries/integrationQueries";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";
import useWindowSize from "~/utils/useWindowSize";

export const ProjectPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const popup = openModal();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sideBar, setSidebar] = useState<{
    open: boolean;
    active: string;
    data?: any;
  }>({ open: false, active: "", data: null });

  const panes = [
    {
      id: "reports",
      label: "Report",
      show:
        isSubscribedFor("project-reports") &&
        isActionAllowed("list-project-reports"),
    },
    {
      id: "docs",
      label: "Documents",
      show: false,
    },
    { id: "tasks", label: "Task Manager", show: false },
    { id: "communication", label: "Communication", show: false },
    { id: "email", label: "Email", show: false },
    {
      id: "team",
      label: "Team",
      show: isActionAllowed("view-project-members"),
    },
    {
      id: "quality",
      label: "Quality Report",
      show:
        isSubscribedFor("project-reports") &&
        isActionAllowed("list-project-reports"),
    },
  ];

  const active =
    searchParams.get("active") || panes.find((p) => p.show)?.id || "";
  const { show, handlePaneSwitch } = usePanes(active);

  const { data: project, isLoading } = useQuery<Project>({
    queryKey: ["project", id],
    queryFn: async () => {
      try {
        const data = await getProject(id);

        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { data: integrations, isLoading: integrloading } = useQuery<
    ListResponse<Integration>
  >({
    queryKey: ["integrations", "connections"],
    enabled:
      isSubscribedFor("tools-integration") &&
      isActionAllowed("list-integrations"),
    queryFn: async () => {
      try {
        const data = await getIntegrations();
        return data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
  });

  const details = [
    {
      title: "Project health",
      value: (
        <span className="mt-1 inline-block">
          <Tracker status={project?.health ?? "on-track"} />
        </span>
      ),
    },
    { title: "Client name", value: project?.client.name },
    { title: "Description", value: project?.description },
    { title: "Service Type", value: project?.serviceType },
    { title: "Start Date", value: getFormattedDate(project?.startDate) },
    { title: "End Date", value: getFormattedDate(project?.endDate) },
    {
      title: "Days Left",
      value: `${project?.daysLeft} days`,
    },
    {
      title: "Time spent",
      value: <ProgressBar progress={project?.daysSpentPercentage || 0} />,
    },
    {
      title: "Work done",
      value: (
        <ProgressBar progress={parseFloat(project?.workDone || "0") || 0} />
      ),
    },

    // { title: 'Project value', value: getFullMoney(15000000) },
  ];

  const handleDelete = () => {
    popup({ component: <DeleteProject id={id} name={project?.name} /> });
  };
  const handleOpenSettings = (setting: string) => {
    setSearchParams(() => {
      searchParams.set("setting", setting);
      return searchParams;
    });

    popup({ component: <Settings /> });
  };

  const windowSize = useWindowSize();

  return isLoading ? (
    <Loader big />
  ) : (
    <div className="w-full h-full flex ">
      <motion.div
        layout
        className="w-auto p-8 h-full flex flex-1 flex-col overflow-scroll "
      >
        <div className="flex items-center w-full justify-between">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-x-2 text-[#525866] text-sm font-medium"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.871 9.75147H16V11.2515H6.871L10.894 15.2745L9.8335 16.335L4 10.5015L9.8335 4.66797L10.894 5.72847L6.871 9.75147Z"
                fill="#525866"
              />
            </svg>
            <span>Back to Projects</span>
          </button>
        </div>
        <Group key="header">
          <div className="flex flex-col gap-4 lg:flex-row lg:items-center w-full justify-between mt-6">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between lg:justify-normal items-center lg:gap-x-2">
                <h1 className="text-[#0A0D14] font-medium text-lg">
                  {project?.name}
                </h1>
                <Pill status={project?.status} />
              </div>
              <span className="text-sm text-[#525866]">
                Project ID: {project?.id.substring(0, 6)}
              </span>
            </div>
            {isActionAllowed("update-project") && (
              <ExpandableButton label="Actions" mono>
                <div className="min-w-[144px] w-full flex flex-col items-start">
                  <button
                    onClick={() => navigate("edit")}
                    className="kebab-button"
                  >
                    Edit
                  </button>
                  <button
                    style={{ color: "#DF1C41" }}
                    onClick={handleDelete}
                    className="kebab-button"
                  >
                    Delete Project
                  </button>
                </div>
              </ExpandableButton>
            )}
          </div>
        </Group>
        <div className="flex flex-col gap-y-[38px] h-full">
          <Group key="top">
            <div className="flex flex-col lg:flex-row mt-6 items-center gap-x-5 w-full ">
              <Group key="left">
                <div className=" shrink-0 h-full flex-1 ">
                  <Group key="details">
                    <div className="bg-white border py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm h-full">
                      <header className="w-full flex items-center justify-between border-b pb-4">
                        <span className="font-medium">Project Information</span>
                      </header>
                      <div className="grid grid-cols-1  lg:grid-cols-3 gap-5 mt-4">
                        {details.map((d) => (
                          <div key={d.title} className="flex flex-col ">
                            <div className="text-xs text-[#525866]">
                              {d.title}
                            </div>
                            <div className="text-sm text-[#0A0D14]">
                              {d.value}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Group>
                </div>
              </Group>
              {isSubscribedFor("tools-integration") &&
                isActionAllowed("list-integrations") &&
                false && (
                  <Group key="right">
                    <div className="h-full shrink-0 w-[40%]">
                      <Group key="details">
                        <div className="bg-white border py-6 px-5 rounded-xl border-[#E2E4E9] shadow-sm h-full">
                          <header className="w-full flex items-center justify-between border-b pb-4">
                            <span className="font-medium">Connected apps</span>
                          </header>
                          {integrloading ? (
                            <Loader big />
                          ) : (
                            <div className="mt-4 flex flex-col gap-y-5">
                              {integrations?.data
                                ?.filter(
                                  (i) => !!i.url && i.hierarchies?.length
                                )
                                .map((i) => (
                                  <div
                                    key={i.id}
                                    className="w-full flex items-center justify-between"
                                  >
                                    <span className="text-sm text-[#525866]">
                                      {i.name}
                                    </span>
                                    {!i.integrated ? (
                                      <button
                                        onMouseDown={() =>
                                          handleOpenSettings("integrations")
                                        }
                                        className="flex text-sm items-center gap-x-3 text-[#525866]"
                                      >
                                        {" "}
                                        Connect App
                                        <svg
                                          width="7"
                                          height="10"
                                          viewBox="0 0 7 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M4.12925 4.99956L0.416748 1.28706L1.47725 0.226562L6.25025 4.99956L1.47725 9.77256L0.416748 8.71206L4.12925 4.99956Z"
                                            fill="#525866"
                                          />
                                        </svg>
                                      </button>
                                    ) : !i.authorized ? (
                                      <a
                                        href={i.url}
                                        target="_blank"
                                        className="flex text-sm items-center gap-x-3 text-[#525866]"
                                      >
                                        Authorize App
                                        <svg
                                          width="7"
                                          height="10"
                                          viewBox="0 0 7 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M4.12925 4.99956L0.416748 1.28706L1.47725 0.226562L6.25025 4.99956L1.47725 9.77256L0.416748 8.71206L4.12925 4.99956Z"
                                            fill="#525866"
                                          />
                                        </svg>
                                      </a>
                                    ) : (
                                      <Pill status="Connected" />
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </Group>
                    </div>
                  </Group>
                )}
            </div>
          </Group>
          <Group key="bottom">
            <div className="flex flex-col gap-y-4 pb-20">
              <TextPanes
                panes={panes}
                active={show}
                handleChange={handlePaneSwitch}
              />
              {show == "docs" && <Documents />}
              {show == "tasks" && <Tasks />}
              {show == "communication" && <Communication />}
              {show == "email" && <Email />}
              {show == "reports" && <Reports id={id!} />}
              {show == "team" && <Team />}
              {show == "quality" && <QualityResults />}
            </div>
          </Group>
        </div>
      </motion.div>
      <Group key="open sidebar">
        <AnimatePresence>
          {sideBar.open && (
            <>
              {/* Overlay */}
              <motion.div
                key="overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black z-[20]"
                onClick={() => setSidebar({ open: false, active: "" })}
              />

              {/* Sidebar */}
              <motion.div
                key="sidebar"
                initial={{ width: 0 }}
                animate={{ width: windowSize.width >= 1024 ? "37%" : "100%" }}
                exit={{ width: 0 }}
                className="fixed top-0 right-0 h-full w-full lg:w-[37%] bg-white flex flex-col border-l z-[30]"
              >
                {sideBar.active !== "chat" && (
                  <Connections
                    id={id}
                    integration={sideBar.data}
                    close={() => setSidebar({ open: false, active: "" })}
                  />
                )}
                {sideBar.active === "chat" && (
                  <ProjectChat
                    id={id!}
                    close={() => setSidebar({ open: false, active: "" })}
                  />
                )}
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </Group>

      <Group key="side bar menu">
        <div className="h-full shrink-0 flex relative border-l z-[30]">
          <div className="bg-white h-full w-[76px] p-3 flex flex-col gap-y-3">
            <div className="relative group">
              <button
                style={{
                  background: sideBar.active == "chat" ? "#EBFAFF" : undefined,
                  fill: sideBar.active == "chat" ? "#35B9E9" : undefined,
                }}
                onMouseDown={() => {
                  if (sideBar.active === "chat") {
                    setSidebar({ open: false, active: "" });
                  } else {
                    setSidebar({ open: true, active: "chat" });
                  }
                }}
                className="h-11 w-full rounded-lg bg-[#F6F8FA] hover:bg-[#EBFAFF] fill-[#525866] hover:fill-[#35B9E9] transition duration-200 ease-in-out flex items-center justify-center"
              >
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.32225 10.75H14V1.75H2V11.7887L3.32225 10.75ZM3.84125 12.25L0.5 14.875V1C0.5 0.801088 0.579018 0.610322 0.71967 0.46967C0.860322 0.329018 1.05109 0.25 1.25 0.25H14.75C14.9489 0.25 15.1397 0.329018 15.2803 0.46967C15.421 0.610322 15.5 0.801088 15.5 1V11.5C15.5 11.6989 15.421 11.8897 15.2803 12.0303C15.1397 12.171 14.9489 12.25 14.75 12.25H3.84125Z"
                    fill="inherit"
                  />
                </svg>
              </button>
              {/* Tooltip */}
              <span className="absolute -left-24 -translate-y-9 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition duration-200">
                Comments
              </span>
            </div>
            <svg
              width="52"
              height="1"
              viewBox="0 0 52 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="52" y2="0.5" stroke="#E2E4E9" />
            </svg>

            {isSubscribedFor("tools-integration") && (
              <>
                <div className="relative group">
                  <button
                    onMouseDown={() => {
                      if (sideBar.active === "connections") {
                        setSidebar({ open: false, active: "" });
                      } else {
                        setSidebar({ open: true, active: "connections" });
                      }
                    }}
                    style={{
                      background:
                        sideBar.active === "connections"
                          ? "#EBFAFF"
                          : undefined,
                      fill:
                        sideBar.active === "connections"
                          ? "#35B9E9"
                          : undefined,
                    }}
                    className="h-11 w-full rounded-lg bg-[#F6F8FA] hover:bg-[#EBFAFF] fill-[#525866] hover:fill-[#35B9E9] transition duration-200 ease-in-out flex items-center justify-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.875 7.625C3.43179 7.625 2.99292 7.5377 2.58344 7.36809C2.17397 7.19848 1.80191 6.94988 1.48851 6.63649C1.17512 6.32309 0.926516 5.95103 0.756907 5.54156C0.587297 5.13208 0.5 4.69321 0.5 4.25C0.5 3.80679 0.587297 3.36792 0.756907 2.95844C0.926516 2.54897 1.17512 2.17691 1.48851 1.86351C1.80191 1.55012 2.17397 1.30152 2.58344 1.13191C2.99292 0.962297 3.43179 0.875 3.875 0.875C4.77011 0.875 5.62855 1.23058 6.26149 1.86351C6.89442 2.49645 7.25 3.35489 7.25 4.25C7.25 5.14511 6.89442 6.00355 6.26149 6.63649C5.62855 7.26942 4.77011 7.625 3.875 7.625ZM4.25 15.125C3.35489 15.125 2.49645 14.7694 1.86351 14.1365C1.23058 13.5035 0.875 12.6451 0.875 11.75C0.875 10.8549 1.23058 9.99645 1.86351 9.36351C2.49645 8.73058 3.35489 8.375 4.25 8.375C5.14511 8.375 6.00355 8.73058 6.63649 9.36351C7.26942 9.99645 7.625 10.8549 7.625 11.75C7.625 12.6451 7.26942 13.5035 6.63649 14.1365C6.00355 14.7694 5.14511 15.125 4.25 15.125ZM11.75 7.625C11.3068 7.625 10.8679 7.5377 10.4584 7.36809C10.049 7.19848 9.67691 6.94988 9.36351 6.63649C9.05012 6.32309 8.80152 5.95103 8.63191 5.54156C8.4623 5.13208 8.375 4.69321 8.375 4.25C8.375 3.80679 8.4623 3.36792 8.63191 2.95844C8.80152 2.54897 9.05012 2.17691 9.36351 1.86351C9.67691 1.55012 10.049 1.30152 10.4584 1.13191C10.8679 0.962297 11.3068 0.875 11.75 0.875C12.6451 0.875 13.5035 1.23058 14.1365 1.86351C14.7694 2.49645 15.125 3.35489 15.125 4.25C15.125 5.14511 14.7694 6.00355 14.1365 6.63649C13.5035 7.26942 12.6451 7.625 11.75 7.625ZM11.75 15.125C10.8549 15.125 9.99645 14.7694 9.36351 14.1365C8.73058 13.5035 8.375 12.6451 8.375 11.75C8.375 10.8549 8.73058 9.99645 9.36351 9.36351C9.99645 8.73058 10.8549 8.375 11.75 8.375C12.6451 8.375 13.5035 8.73058 14.1365 9.36351C14.7694 9.99645 15.125 10.8549 15.125 11.75C15.125 12.6451 14.7694 13.5035 14.1365 14.1365C13.5035 14.7694 12.6451 15.125 11.75 15.125Z"
                        fill="inherit"
                      />
                    </svg>
                  </button>
                  {/* Tooltip */}
                  <span className="absolute -left-24 -translate-y-9 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition duration-200">
                    Project Resources
                  </span>
                </div>

                {integrations?.data
                  ?.filter((i) => !!i.url && i.hierarchies?.length)
                  .map((i) => (
                    <div className="relative group">
                      <button
                        key={i.id}
                        onMouseDown={() => {
                          if (
                            !i.integrated &&
                            isActionAllowed("set-integration")
                          ) {
                            handleOpenSettings("integrations");
                          } else if (
                            !i.authorized &&
                            isActionAllowed("add-project-integration") &&
                            !Date.now() // falsy
                          ) {
                            window.open(i.url, "_blank");
                          } else {
                            if (sideBar.active === i.slug) {
                              setSidebar({ open: false, active: "" });
                            } else {
                              setSidebar({
                                open: true,
                                active: i.slug,
                                data: i,
                              });
                            }
                          }
                        }}
                        style={{
                          background:
                            sideBar.active === i.slug ? "#EBFAFF" : undefined,
                          fill:
                            sideBar.active === i.slug ? "#35B9E9" : undefined,
                        }}
                        className="h-11 w-full rounded-lg bg-[#F6F8FA] hover:bg-[#EBFAFF] fill-[#525866] hover:fill-[#35B9E9] transition duration-200 ease-in-out flex items-center justify-center"
                      >
                        <img src={i.icon} alt={i.name} className="w-8" />
                      </button>
                      {/* Tooltip */}
                      <span className="absolute -left-24 -translate-y-9 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition duration-200">
                        {i.name}
                      </span>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </Group>
    </div>
  );
};
