import { useState, useEffect, useRef } from "react";
import {
  Button,
  CopyButton,
  Group,
  Header,
  SelectElement,
  TextInput,
} from "~/components";
import { SettingContainer } from "../../../components";
import { useForm } from "~/hooks";
import { useAuth } from "~/providers";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateWorkspace, uploadProfile } from "~/api";
import { MAX_FILE_SIZE } from "~/constants/values";
import { AnimatePresence, motion } from "framer-motion";
import { fadeIn } from "~/constants/animations";
import Loader from "~/components/UI/Loader";

export const General = () => {
  const theForm = new FormData();

  const { workspace, setWorkspace } = useAuth();
  const [showAdd, setShowAdd] = useState(false);

  const requestUrl = new URL(window.origin).hostname + "/requests/create";
  const [services, setServices] = useState<any>(
    () => workspace?.serviceTypes ?? []
  );

  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const { formData, update } = useForm({
    initial: {
      slug: workspace?.slug,
      choice: workspace.requireTwoFA ? 1 : 0,
      assistantEnabled: workspace.assistantEnabled,
      allowedDomains: workspace.requiredEmailDomains
        ? workspace.requiredEmailDomains.join(", ")
        : "",
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (logoUrl?: string) =>
      updateWorkspace(workspace.id, {
        ...workspace,
        serviceTypes: services,
        slug: formData.slug,
        logoUrl: logoUrl,
        domain: `${formData.slug}.wholistic.work`,
        requireTwoFA: formData.choice == 1 ? true : false,
        assistantEnabled: formData.assistantEnabled
          ? formData.assistantEnabled.toString() == "true"
            ? true
            : false
          : null,
        requiredEmailDomains: formData.allowedDomains
          .split(",")
          .map((domain: any) => domain.trim())
          .filter(Boolean),
      }),
    onSuccess(data: any) {
      toast.success(`Workspace Updated successfully`);
      if (workspace.slug != formData.slug) {
        window.location.href = `https://${formData.slug}.wholistic.work`;
      } else {
        setWorkspace({
          ...workspace,
          serviceTypes: services,
          slug: formData.slug,
          logoUrl: data.data.logoUrl,
          domain: `${formData.slug}.wholistic.work`,
          requireTwoFA: formData.choice == 1 ? true : false,
          assistantEnabled: formData.assistantEnabled
            ? formData.assistantEnabled.toString() == "true"
              ? true
              : false
            : null,
          requiredEmailDomains: formData.allowedDomains
            .split(",")
            .map((domain: any) => domain.trim()),
        });
      }
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const { mutate: upload, isPending: uploading } = useMutation({
    mutationFn: (theForm: any) => uploadProfile(theForm),
    ...{
      onSuccess(data: { url: string }) {
        toast.success(`Adding logo to workspace`);
        mutate(data.url);
      },
      onError(error) {
        toast.error(error.message);
      },
    },
  });
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: any) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      setServices([...services, inputValue.trim()]);
      setInputValue("");
    } else if (
      e.key === "Backspace" &&
      inputValue === "" &&
      services.length > 0
    ) {
      setServices(services.slice(0, -1));
    }
  };

  const removeService = (index: any) => {
    setServices(services.filter((_: any, i: any) => i !== index));
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [services]);
  const handleAvatarChange = (event: any) => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      toast.error("File size exceeds the maximum limit of 5MB.");
      return;
    }
    theForm.append("file", file);
    upload(theForm);
  };
  return (
    <div className="w-full flex flex-col">
      <Header title="Workspace" subtext="Manage your workspace." />

      <div className="rounded-xl w-full border border-[#E2E4E9] mt-6">
        <div className="rounded-t-xl w-full flex flex-col items-center pb-5 pt-8 gap-y-3 bg-[#F5F7FA] overflow-scroll lg:overflow-auto">
          <div
            onMouseEnter={() => setShowAdd(true)}
            onMouseLeave={() => setShowAdd(false)}
            className="w-10 h-10 lg:w-20 lg:h-20 rounded-lg bg-[#E255F2] flex items-center justify-center text-2xl  text-white font-medium uppercase relative overflow-clip"
          >
            {uploading ? (
              <Loader bgColor="#ffffff" />
            ) : workspace.logoUrl ? (
              <img
                src={workspace.logoUrl}
                className="w-full h-full object-cover"
              />
            ) : (
              <>
                {workspace?.name?.[0]}
                {workspace?.name?.split(" ")?.[1]?.[0]}
              </>
            )}
            <Group key="upload fab">
              <input
                className="hidden"
                accept=".jpg, .jpeg, .png"
                onChange={handleAvatarChange}
                type="file"
                disabled={uploading}
                id="avatar"
              />
              <AnimatePresence>
                {showAdd ? (
                  <motion.div
                    variants={fadeIn}
                    animate="animate"
                    exit="exit"
                    initial="initial"
                    className="absolute inset-0 w-full h-full flex items-center justify-center bg-[#1D1D1D]/[70%] rounded-lg"
                  >
                    {uploading ? (
                      <Loader />
                    ) : (
                      <label
                        htmlFor="avatar"
                        className="bg-white rounded-full p-[5px] flex justify-center cursor-pointer items-center gap-x-[9px] text-xs text-[#263238] font-semibold"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5452 2.69844L5.7452 4.49844H2.3V15.2984H16.7V4.49844H13.2548L11.4548 2.69844H7.5452ZM6.8 0.898438H12.2L14 2.69844H17.6C17.8387 2.69844 18.0676 2.79326 18.2364 2.96204C18.4052 3.13082 18.5 3.35974 18.5 3.59844V16.1984C18.5 16.4371 18.4052 16.6661 18.2364 16.8348C18.0676 17.0036 17.8387 17.0984 17.6 17.0984H1.4C1.16131 17.0984 0.932387 17.0036 0.763604 16.8348C0.594821 16.6661 0.5 16.4371 0.5 16.1984V3.59844C0.5 3.35974 0.594821 3.13082 0.763604 2.96204C0.932387 2.79326 1.16131 2.69844 1.4 2.69844H5L6.8 0.898438ZM9.5 14.3984C8.18718 14.3984 6.92813 13.8769 5.99982 12.9486C5.07152 12.0203 4.55 10.7613 4.55 9.44844C4.55 8.13562 5.07152 6.87656 5.99982 5.94826C6.92813 5.01995 8.18718 4.49844 9.5 4.49844C10.8128 4.49844 12.0719 5.01995 13.0002 5.94826C13.9285 6.87656 14.45 8.13562 14.45 9.44844C14.45 10.7613 13.9285 12.0203 13.0002 12.9486C12.0719 13.8769 10.8128 14.3984 9.5 14.3984ZM9.5 12.5984C10.3354 12.5984 11.1366 12.2666 11.7274 11.6758C12.3181 11.0851 12.65 10.2839 12.65 9.44844C12.65 8.61301 12.3181 7.81179 11.7274 7.22105C11.1366 6.63031 10.3354 6.29844 9.5 6.29844C8.66457 6.29844 7.86335 6.63031 7.27261 7.22105C6.68187 7.81179 6.35 8.61301 6.35 9.44844C6.35 10.2839 6.68187 11.0851 7.27261 11.6758C7.86335 12.2666 8.66457 12.5984 9.5 12.5984Z"
                            fill="#0A0D14"
                          />
                        </svg>
                      </label>
                    )}
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </Group>
          </div>
          <span className="font-medium text-[#0A0D14]">Workspace Logo</span>
        </div>
        <div className="bg-white rounded-b-xl px-5 flex-col overflow-x-scroll lg:overflow-auto">
          <SettingContainer title="Workspace name">
            <div className="lg:max-w-[50%] w-full">
              <TextInput
                name="name"
                readOnly
                value={workspace.name}
                handleInputChange={update}
              />
            </div>
          </SettingContainer>
          {workspace?.slug !== "admin" && (
            <SettingContainer title="Workspace URL">
              <div className="lg:max-w-[50%] w-full">
                <div className="border border-[#E2E4E9] rounded-xl flex flex-col-reverse lg:flex-row-reverse lg:items-center mt-1">
                  <span className="border-l px-3 py-[10px] text-[#868C98] text-sm shrink-0">
                    .wholistic.work
                  </span>
                  <input
                    name="slug"
                    placeholder="URL name"
                    value={formData.slug}
                    onChange={update}
                    className="px-3 py-[10px] bg-white text-sm outline-none w-full rounded-l-xl"
                  />
                </div>
              </div>
            </SettingContainer>
          )}
          <SettingContainer title="Allowed domains">
            <div className="lg:max-w-[50%] w-full">
              <TextInput
                name="allowedDomains"
                placeholder="Enter comma-separated domains"
                value={formData.allowedDomains}
                handleInputChange={update}
              />
            </div>
          </SettingContainer>
          {workspace?.slug !== "admin" && (
            <SettingContainer title="Request URL">
              <div className="flex flex-col lg:flex-row lg:items-center gap-x-3">
                <span className="text-sm w-[100px] break-words lg:whitespace-nowrap text-[#868C98]">
                  {requestUrl}
                </span>
                <CopyButton value={requestUrl} />
              </div>
            </SettingContainer>
          )}
          {workspace?.slug !== "admin" && (
            <SettingContainer
              title="Services"
              subtext="Add custom services you offer. They'll be made available when creating new projects."
            >
              <div
                ref={containerRef}
                onClick={handleContainerClick}
                className="w-full lg:max-w-[50%] min-h-[100px] max-h-[120px] py-[10px] px-3 border border-[#E2E4E9] rounded-xl overflow-y-auto flex flex-wrap items-start content-start gap-2"
              >
                {services?.map((service: any, index: any) => (
                  <div
                    key={index}
                    className="flex items-center bg-white border border-[#E2E4E9] text-xs rounded-[6px] px-[5px] py-1 text-[#525866]"
                  >
                    <span className="mr-1">{service}</span>
                    <button
                      onClick={() => removeService(index)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 6.22234L9.72234 3.5L10.5 4.27766L7.77766 7L10.5 9.72234L9.72234 10.5L7 7.77766L4.27766 10.5L3.5 9.72234L6.22234 7L3.5 4.27766L4.27766 3.5L7 6.22234Z"
                          fill="#868C98"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
                <input
                  ref={inputRef}
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  className="flex-grow min-w-[30px] outline-none text-sm placeholder:text-[#CDD0D5] text-[#525866]"
                  placeholder={
                    services?.length === 0
                      ? "Start typing to add a service and hit ‘Enter' to save"
                      : ""
                  }
                />
              </div>
            </SettingContainer>
          )}
          {workspace?.slug !== "admin" && (
            <SettingContainer
              title="Two-Factor Authentication (2FA)"
              subtext="Workspace members will be mandated to enable 2FA on their next login. Check the Members page to see who has 2FA enabled or not."
            >
              <div className="lg:max-w-[50%] w-full">
                <SelectElement
                  name="choice"
                  options={[
                    { label: "2FA is not mandatory", value: 0 },

                    { label: "2FA is mandatory", value: 1 },
                  ]}
                  value={formData.choice}
                  onChange={update}
                />
              </div>
            </SettingContainer>
          )}
          {workspace?.slug !== "admin" && (
            <SettingContainer
              title="AI Assistant"
              subtext="Workspace members will have access to the AI assistant throughout their application"
            >
              <div className="lg:max-w-[50%] w-full">
                <SelectElement
                  name="assistantEnabled"
                  options={[
                    { label: "Disable", value: false },

                    { label: "Enable", value: true },
                  ]}
                  placeholder="Make a choice"
                  value={formData.assistantEnabled}
                  onChange={update}
                />
              </div>
            </SettingContainer>
          )}
          <div className="w-full flex justify-end py-5">
            <Button
              effect={() => mutate(workspace.logoUrl)}
              loading={isPending}
              disabled={
                formData.slug == workspace.slug &&
                Boolean(formData.choice) == workspace.requireTwoFA &&
                formData.assistantEnabled === workspace.assistantEnabled &&
                formData.allowedDomains ===
                  workspace.requiredEmailDomains?.join(", ") &&
                (workspace.serviceTypes?.toString() == null
                  ? services?.length == 0
                  : workspace.serviceTypes?.toString() == services?.toString())
              }
              label="Save changes"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
