/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProjectHealthStats, getProjectStats } from "~/api";
import {
  Button,
  Header,
  Kebab,
  Pagination,
  Pill,
  ProgressBar,
  SearchBar,
  TextPanes,
  Tracker,
} from "~/components";
import Loader from "~/components/UI/Loader";
import { usePagination, usePanes } from "~/hooks";
import { getProjects } from "~/queries/projectQueries";
import { isActionAllowed } from "~/utils/authHelper";
import { getFormattedDate } from "~/utils/dateUtils";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import React from "react";

interface TableProps {
  cols: readonly ColumnDef<any>[];
  rawData: any[];
  clickFunction?: (rowData: any) => void;
  linkPrefix?: string;
  linkSuffix?: string;
  extra?: string;
  loading?: boolean;
  useData?: boolean;
  truncate?: boolean;
}

export const ProjectsPage = () => {
  const { handlePageChange } = usePagination();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const active = searchParams.get("active") || "";
  const search = searchParams.get("search") || "";
  const health = searchParams.get("health") || "";

  const page = searchParams.get("page") || 1;
  const limit = searchParams.get("limit") || 10;
  const { show, handlePaneSwitch } = usePanes(active, true);

  const { data: projects, isLoading } = useQuery({
    queryKey: ["projects", { page, limit, show, search, health }],
    queryFn: async () => {
      try {
        const data = await getProjects({
          page,
          limit,
          "filter[status]": !health ? show : undefined,
          "filter[name]": search,
          "filter[health]": health ? show : undefined,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const { data: projectStats, isLoading: statsLoading } = useQuery({
    queryKey: ["projects", "stats"],
    queryFn: async () => {
      try {
        const data = await getProjectStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const { data: projectHealthStats, isLoading: healthStatsLoading } = useQuery({
    queryKey: ["project health", "stats"],
    queryFn: async () => {
      try {
        const data = await getProjectHealthStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const getTotalCount = (array: any[]) => {
    return array?.reduce((total, obj) => {
      return total + parseInt(obj?.count, 10);
    }, 0);
  };

  const panes = health
    ? [
        ...(projectHealthStats?.map((stat: any) => ({
          id: stat.value,
          label: stat.value
            ?.replace("-", " ")
            ?.replace(/\b\w/g, (char: string) => char.toUpperCase()),
          show: true,
          value: stat.count,
        })) || []),
      ]
    : [
        {
          id: "",
          label: "All",
          show: true,
          value:
            getTotalCount(projectStats) === 0
              ? "0"
              : getTotalCount(projectStats),
        },
        ...(projectStats
          ?.filter(
            (s: any) => !["pending", "rejected", "accepted"].includes(s.value)
          )
          .map((stat: any) => ({
            id: stat.value,
            label: stat.value?.replace(/\b\w/g, (char: string) =>
              char.toUpperCase()
            ),
            show: true,
            value: stat.count,
          })) || []),
      ];
  const colums: ColumnDef<Project>[] = [
    {
      accessorFn: (row) => row.name,
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Project </span>,
      size: 15,
    },
    {
      accessorFn: (row) => row.client.name,
      id: "email",
      cell: (info) => info.getValue(),
      header: () => <span>Client</span>,
      size: 12,
    },
    {
      id: "start date",
      cell: ({ row }) => getFormattedDate(row.original?.startDate),
      header: () => <span>Start Date</span>,
      size: 8,
    },
    {
      id: "end date",
      cell: ({ row }) => getFormattedDate(row.original?.endDate),
      header: () => <span>End Date</span>,
      size: 8,
    },
    {
      id: "work done",
      cell: ({ row }) => (
        <ProgressBar progress={parseFloat(row.original.workDone)} />
      ),
      header: () => <span>Work Done</span>,
      size: 10,
    },
    {
      id: "time spent",
      cell: ({ row }) => (
        <ProgressBar progress={row.original.daysSpentPercentage} />
      ),
      header: () => <span>Time spent</span>,
      size: 10,
    },
    {
      id: "days left",
      cell: ({ row }) => {
        return (
          <span className=" block text-center">
            {row.original.daysLeft} days
          </span>
        );
      },
      header: () => <span className=" block text-center">Days left</span>,
      size: 13,
    },
    {
      accessorFn: (row) => row.status,
      id: "health",
      cell: ({ row }) => <Tracker status={row.original.health ?? "on-track"} />,
      header: () => <span>Project Health</span>,
      size: 10,
    },
    {
      accessorFn: (row) => row.status,
      id: "status",
      cell: ({ row }) => <Pill status={row.original.status} />,
      header: () => <span>Status</span>,
      size: 10,
    },
    {
      id: "event",
      cell: ({ row }) =>
        isActionAllowed("update-project") && (
          <Kebab>
            <div className="flex flex-col w-[100px]">
              <button
                onClick={() => navigate(`${row.original.id}/edit`)}
                className="kebab-button"
              >
                Edit Project
              </button>
            </div>
          </Kebab>
        ),
      header: () => <span> </span>,
      size: 4,
    },
  ];

  const handleClick = (data: Project) => {
    navigate(data.id);
  };
  const searchFunction = (v: string) => {
    setSearchParams(() => ({
      search: v!,
      show: show!,
      page: "1",
      limit: "10",
    }));
    close?.();
  };
  const clearFunction = () => {
    setSearchParams(new URLSearchParams());
  };
  return statsLoading || healthStatsLoading ? (
    <Loader big />
  ) : (
    <div className="flex flex-col w-full h-full p-8">
      <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row lg:items-center lg:justify-between">
        <Header title="Projects" subtext="Manage all your projects." />
        {isActionAllowed("create-project") && (
          <Button
            label="Create a project"
            effect={() => navigate("create")}
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                  fill="white"
                />
              </svg>
            }
            leftIcon
          />
        )}
      </div>
      <div className="flex flex-col mt-6 w-full">
        <TextPanes
          panes={panes}
          active={show}
          handleChange={handlePaneSwitch}
        />
        <div className="mt-6 bg-white w-full rounded-xl flex flex-col p-4 gap-y-5  min-h-[400px]">
          <div className="flex items-center gap-x-2">
            {" "}
            <SearchBar
              placeholder="Search..."
              initialValue={search}
              onClear={clearFunction}
              onSearch={searchFunction}
            />
          </div>
          <div className=" overflow-x-scroll">
            {isLoading ? (
              <Loader big />
            ) : projects?.data?.length > 0 ? (
              <div className="w-[150%] h-full overflow-x-clip">
                <Table
                  clickFunction={handleClick}
                  useData
                  key="projects"
                  cols={colums}
                  rawData={projects?.data}
                />
              </div>
            ) : (
              <div className="  w-full h-full min-h-[400px] rounded-xl flex flex-col items-center justify-center text-center">
                <div className="max-w-[260px] w-full flex flex-col items-center gap-y-5 ">
                  <img src="/empty.svg" className="w-[100px]" />
                  <div className="flex flex-col items-center gap-y-2">
                    <span className=" text-sm text-[#0A0D14]">
                      No {show} projects to show
                    </span>
                    {isActionAllowed("create-project") && (
                      <span className=" text-sm text-[#525866]">
                        You have not added any new projects. Create one to get
                        started
                      </span>
                    )}
                  </div>
                  {isActionAllowed("create-project") && (
                    <Button
                      mono
                      effect={() => navigate("create")}
                      label="Create a project"
                      icon={
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.25 9.25V4.75H10.75V9.25H15.25V10.75H10.75V15.25H9.25V10.75H4.75V9.25H9.25Z"
                            fill="black"
                          />
                        </svg>
                      }
                      leftIcon
                      // effect={() => popup({ component: <AddRole /> })}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {projects?.meta?.totalNumberOfRecords > 10 && (
          <Pagination
            page={page}
            limit={limit}
            pages={projects?.meta?.totalNumberOfPages}
            total={projects?.meta?.totalNumberOfRecords}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export const Table = ({
  cols,
  rawData,
  clickFunction,
  linkPrefix,
  linkSuffix,
  extra,
  useData,
}: TableProps) => {
  const columns: ColumnDef<any, any>[] = [...cols];
  const data = React.useMemo(() => [...rawData], [rawData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full overflow-x-auto h-full">
      <table className="w-full min-w-[640px]">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="bg-[#F6F8FA] text-sm text-[#525866] font-normal py-2 px-3 text-left first:rounded-l-lg last:rounded-r-lg"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={`border-b last:border-none text-[#0A0D14] transition-all duration-200 ${
                clickFunction ? "hover:bg-[#f5f5f5] hover:cursor-pointer" : ""
              }`}
              onClick={() =>
                clickFunction
                  ? useData
                    ? clickFunction(row.original)
                    : clickFunction(
                        `${linkPrefix ? linkPrefix : ""}${
                          row.original.clientId || row.original.id
                        }${linkSuffix ? linkSuffix : ""}${
                          extra ? row.original[extra] : ""
                        }`
                      )
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-[14px] px-3 text-sm">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
