/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useState } from "react";
import Loader from "../Loader";
import { AnimatePresence, motion } from "framer-motion";
import "./styles.css";
import { dropdown } from "~/constants/animations";
import { useOutsideClick } from "~/hooks";

interface ButtonProps {
  primary?: boolean;
  danger?: boolean;
  yellow?: boolean;
  mono?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: any;
  label?: string;
  children: ReactNode;
}

export const ExpandableButton = ({
  primary = false,
  mono = false,
  danger = false,
  yellow = false,
  icon,
  children,
  disabled = false,
  label = "Button",
  loading = false,
}: ButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const mode = primary
    ? " bg-[#35B9E9] text-white duration-200 transition disabled:bg-[#F1F1F4] disabled:text-[#CDD0D5]"
    : danger
    ? "bg-white text-[#DF1C41] border border-[#DF1C41] hover:bg-[#DF1C41] hover:text-white hover:shadow-none duration-200 transition  "
    : yellow
    ? "bg-white text-[#263238] bg-[#F9CF1C] duration-200 transition  "
    : mono
    ? "bg-white border border-[#E2E4E9] text-[#525866] duration-200 transition   "
    : " bg-[#35B9E9] text-white duration-200 transition disabled:bg-[#F1F1F4]/60 disabled:text-[#CDD0D5]";

  const containerRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  function handleClickOutside(e: any) {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }
  return (
    <div className="relative ">
      <button
        className={[
          "btn flex items-center gap-x-1",
          `${mode}`,
          ` rounded-xl`,
        ].join(" ")}
        disabled={disabled || loading}
        onClick={() => setIsOpen(true)}
        type="button"
      >
        {loading ? (
          <span className="w-full grid place-items-center relative">
            <span className="absolute z-10">
              <Loader bgColor="#2C1DFF" />
            </span>
            <span className="opacity-0">{label}</span>
          </span>
        ) : (
          <>
            {icon && icon}

            <span className="px-1">{label}</span>
            {mono ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99999 10.8805L13.7125 7.16797L14.773 8.22847L9.99999 13.0015L5.22699 8.22847L6.28749 7.16797L9.99999 10.8805Z"
                  fill="#525866"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99999 10.8785L13.7125 7.16602L14.773 8.22652L9.99999 12.9995L5.22699 8.22652L6.28749 7.16602L9.99999 10.8785Z"
                  fill="white"
                />
              </svg>
            )}
          </>
        )}
      </button>{" "}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            onClick={(e) => e.stopPropagation()}
            variants={dropdown}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute bg-white shadow-md mt-[6px] px-3 py-2 w-fit rounded-xl right-0 z-10 border border-[#E2E4E9]"
            ref={containerRef}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
