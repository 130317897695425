import { Group } from "../../components";
import { ReactNode } from "react";

export const AuthScreen = ({
  icon,
  children,
}: {
  icon: string;
  children: ReactNode;
}) => {
  return (
    <div className=" w-full h-full lg:flex items-center justify-center gap-x-[230px] overflow-y-scroll">
      <Group key="left">
        <div className="max-w-[456px] w-full">{children}</div>
      </Group>
      <Group key="right">
        <div className="hidden lg:block min-w-[410px]">
          <img src={icon} alt="onboarding graphic" />
        </div>
      </Group>
    </div>
  );
};
