/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import toast from "react-hot-toast";
import { STORAGE_KEYS } from "~/constants/values";

axios.interceptors.request.use(async (request: any): Promise<any> => {
  const { accessToken: token } = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.TOKEN_DATA) ||
      JSON.stringify({
        tokenType: "",
        accessToken: "",
        expiresIn: 0,
      })
  );

  if (!request.headers?.Authorization && token) {
    request!.headers!.Authorization = `Bearer ${token}`;
  }
  if (request.params) {
    const newParams: any = {};
    Object.entries(request.params).forEach((p) => {
      if (p[1]) {
        newParams[p[0]] = p[1];
      }
    });
    request.params = { ...newParams };
  }
  request.timeout = 60000;
  return request;
});

const getErrorMessage = (
  data: any
): { validationErrors: string[]; message: string } => {
  const parts = ["data", "filter", "params"];
  const key = Object.keys(data).find((k) => parts.includes(k));
  let message, validationErrors;
  if (key) {
    message = data[key][Object.keys(data[key])[0]];
    if (typeof message === "object" && message !== null) {
      message = getErrorMessage(data[key])?.message;
    }
    validationErrors = data[key];
  } else {
    message = data.message;
  }
  return {
    validationErrors,
    message,
  };
};

// Function to handle response errors
const handleResponseError = (error: any) => {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 401) {
      error.message = "Unauthorized access";
      toast.error(error.message);
      window.location.pathname = "/";
    } else if (data?.message) {
      const { message, validationErrors } = getErrorMessage(data);
      error.response.validationErrors = validationErrors;
      error.response.message = message || "Validation Error";
    } else {
      error.message = "An error occurred";
    }
  } else {
    error.message = "Network Error";
  }
  return Promise.reject(error);
};
// Intercept all responses
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => handleResponseError(error)
);

export default axios;
