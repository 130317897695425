/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getProjectStats } from "~/api";
import { getClientStats } from "~/queries/clientQueries";
import { Inbox } from "./components";
import { GettingStarted } from "~/components";
import { ProjectHealth } from "./charts";
import { useAuth } from "~/providers";
import Loader from "~/components/UI/Loader";
import { isActionAllowed, isSubscribedFor } from "~/utils/authHelper";

export const OrganizationDashboard = () => {
  const { user } = useAuth();
  const { data: clientStats, isLoading: statsLoading } = useQuery({
    queryKey: ["clients", "stats"],
    enabled: isSubscribedFor("clients") && isActionAllowed("list-clients"),
    queryFn: async () => {
      try {
        const data = await getClientStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  const getStat = (key: string) => {
    if (!clientStats) return "";
    const [stat] = clientStats.filter((st: any) => st.value == key);
    return stat && stat.count ? stat?.count : 0;
  };
  const { data: projectStats, isLoading: projectStatsLoading } = useQuery({
    queryKey: ["projects", "stats"],
    enabled: isSubscribedFor("projects") && isActionAllowed("list-projects"),
    queryFn: async () => {
      try {
        const data = await getProjectStats();
        return data.data;
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });
  const getProjectStat = (key: string) => {
    if (!projectStats) return "";
    const [stat] = projectStats.filter((st: any) => st.value == key);
    return stat && stat.count ? stat?.count : 0;
  };
  return statsLoading || projectStatsLoading ? (
    <Loader big />
  ) : (
    <div className="mt-4 lg:mt-0 flex flex-col w-full h-full p-8  ">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-lg text-[#0A0D14] font-medium">
            Welcome {user?.firstName} {user.lastName}!
          </h1>
          <p className="text-sm text-[#525866]">
            Here are the recent stats for your workspace so far.
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-x-5 h-full gap-y-10">
        <div className="flex items-center lg:flex-row flex-col gap-5 lg:max-h-[308px]">
          {isSubscribedFor("projects") && (
            <div className="flex flex-col gap-y-5 lg:max-w-[30%] w-full shrink-0">
              <ProjectHealth />
            </div>
          )}
          <div className="flex flex-col gap-y-5 w-full h-full">
            <div className="bg-white rounded-lg border border-[#E2E4E9] shadow-sm h-full grid sm:grid-cols-3 grid-cols-1">
              <div className="flex flex-col p-4 sm:border-r sm:border-b-0 border-r-0 border-b">
                <span className="text-sm text-[#0E121B] font-medium">
                  Pending Requests
                </span>
                <h2 className="text-[40px] font-medium mt-auto text-[#0E121B]">
                  {getProjectStat("pending")}
                </h2>
                <span className="text-sm text-[#99A0AE]">
                  From 0 (last 30 days)
                </span>
              </div>
              <div className="flex flex-col p-4 sm:border-r sm:border-b-0 border-r-0 border-b">
                <span className="text-sm text-[#0E121B] font-medium">
                  Scheduled Projects
                </span>
                <h2 className="text-[40px] font-medium mt-auto text-[#0E121B]">
                  {getProjectStat("scheduled")}
                </h2>
                <span className="text-sm text-[#99A0AE]">
                  From 0 (last 30 days)
                </span>
              </div>
              <div className="flex flex-col p-4  ">
                <span className="text-sm text-[#0E121B] font-medium">
                  Active Clients
                </span>
                <h2 className="text-[40px] font-medium mt-auto text-[#0E121B]">
                  {getStat("active")}
                </h2>
                <span className="text-sm text-[#99A0AE]">
                  From 0 (last 30 days)
                </span>
              </div>
            </div>

            <div className="bg-white rounded-lg border border-[#E2E4E9] shadow-sm h-full grid sm:grid-cols-3 grid-cols-1">
              <div className="flex flex-col p-4 sm:border-r sm:border-b-0 border-r-0 border-b">
                <span className="text-sm text-[#0E121B] font-medium">
                  Declined Requests
                </span>
                <h2 className="text-[40px] font-medium mt-auto text-[#0E121B]">
                  {getProjectStat("rejected")}
                </h2>
                <span className="text-sm text-[#99A0AE]">
                  From 0 (last 30 days)
                </span>
              </div>
              <div className="flex flex-col p-4 sm:border-r sm:border-b-0 border-r-0 border-b">
                <span className="text-sm text-[#0E121B] font-medium">
                  Completed Projects
                </span>
                <h2 className="text-[40px] font-medium mt-auto text-[#0E121B]">
                  {getProjectStat("completed")}
                </h2>
                <span className="text-sm text-[#99A0AE]">
                  From 0 (last 30 days)
                </span>
              </div>
              <div className="flex flex-col p-4  ">
                <span className="text-sm text-[#0E121B] font-medium">
                  Inactive Clients
                </span>
                <h2 className="text-[40px] font-medium mt-auto text-[#0E121B]">
                  {getStat("inactive")}
                </h2>
                <span className="text-sm text-[#99A0AE]">
                  From 0 (last 30 days)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 w-full h-full min-h-[400px]">
          <Inbox />
        </div>
      </div>
      <GettingStarted />
    </div>
  );
};
