/* eslint-disable @typescript-eslint/no-explicit-any */

export const WorkspaceRadioButton = ({
  value,
  selected,
  updateData,
  name,
  workspace,
}: {
  updateData: any;
  selected: boolean;
  name: string;
  value: string;
  workspace: Workspace;
}) => {
  return (
    <>
      <label
        className="flex items-center gap-x-2 cursor-pointer border hover:bg-[#E2E4E9] rounded-lg p-3 w-full transition-colors duration-200 ease-in-out "
        style={{
          backgroundColor: selected ? "#F6F8FA" : "#FFFFFF",
          borderColor: selected ? "#868C98" : "#E2E4E9",
          opacity: 0.8,
        }}
        htmlFor={value}
      >
        <div className="flex flex-col lg:flex-row w-full items-center justify-between gap-y-1 gap-x-2">
          <>
            <span className="w-10 h-10 rounded shrink-0 bg-[#E255F2] overflow-clip flex items-center justify-center font-medium text-white uppercase">
              {workspace?.name?.split(" ")?.[0]?.[0]}
              {workspace?.name?.split(" ")?.[1]?.[0]}
            </span>
            <span className="font-medium text-lg text-[#0A0D14] uppercase truncate">
              {workspace?.name}
            </span>
          </>
          {workspace.slug !== "admin" && (
            <span
              style={{
                color: workspace.subscription
                  ? workspace.subscription?.plan?.name?.toLowerCase() == "free"
                    ? "#B47818"
                    : "#2D9F75"
                  : "#B47818",
                borderColor: workspace.subscription
                  ? workspace.subscription?.plan?.name?.toLowerCase() == "free"
                    ? "#B47818"
                    : "#2D9F75"
                  : "#B47818",
              }}
              className=" py-[2px] px-2 border rounded-full font-medium text-[11px] uppercase flex items-center gap-x-1"
            >
              <img
                src={
                  workspace.subscription
                    ? workspace.subscription?.plan?.name.toLowerCase() == "free"
                      ? "/leaf.svg"
                      : "/bolt.svg"
                    : "/leaf.svg"
                }
              />
              {workspace.subscription?.plan?.name ?? "Free Plan"} Plan
            </span>
          )}
        </div>
      </label>

      <input
        id={value}
        className="hidden"
        type="radio"
        name={name}
        value={value}
        checked={selected}
        onChange={updateData}
      />
    </>
  );
};
