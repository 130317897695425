import { motion } from "framer-motion";

export interface PanesProps {
  panes: {
    id: string;
    label: string;
    value?: string | number;
    show?: boolean;
  }[];
  active: string;
  handleChange: (id: string) => void;
}

export const TextPanes = ({ panes, active, handleChange }: PanesProps) => {
  const visiblePanes = panes.filter((pane) => pane.show);

  return (
    <motion.div key={panes.toString()}>
      {/* Dropdown for mobile */}
      <div className="lg:hidden">
        <select
          className="w-full border border-gray-300 rounded-md p-2 text-sm"
          value={active}
          onChange={(e) => handleChange(e.target.value)}
        >
          {visiblePanes.map((pane) => (
            <option key={pane.id} value={pane.id}>
              {pane.label} {pane.value && `(${pane.value})`}
            </option>
          ))}
        </select>
      </div>

      {/* Buttons for larger screens */}
      <ul className="hidden lg:flex gap-5 flex-wrap lg:flex-nowrap w-full items-center">
        {visiblePanes.map((pane) => (
          <li key={pane.id}>
            <motion.button
              onClick={() => handleChange(pane.id)}
              key={pane.label}
            >
              <div className="flex items-center">
                <span
                  style={{
                    color: active === pane.id ? "#1F87AD" : "#868C98",
                  }}
                  className="transition duration-200 ease-in-out text-sm font-medium"
                >
                  {pane.label} {pane.value && `(${pane.value})`}
                </span>
              </div>
            </motion.button>
          </li>
        ))}
      </ul>
    </motion.div>
  );
};
