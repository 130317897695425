import { Kebab, Pill } from "~/components";
import { openModal, openSideModal } from "~/providers";
import { Connect } from "../Connect";
import { Guide } from "../IntegrationGuide";
import { DeleteIntegration } from "../Delete";
// import { Link } from "react-router-dom";

export const IntegrationCard = ({
  details,
  refetch,
  view,
}: {
  details: Integration;
  refetch: () => void;
  view: (v: Integration) => void;
}) => {
  const popup = openModal();
  const slide = openSideModal();

  const handlePopup = () => {
    popup({ component: <Connect details={details} refetch={refetch} /> });
  };

  const handleGuidePopup = () => {
    slide({ component: <Guide details={details} refetch={refetch} /> });
  };
  const handleDisconnect = () => {
    popup({
      component: <DeleteIntegration details={details} refetch={refetch} />,
    });
  };

  // const howToLinks: { [key: string]: string } = {
  //   slack: "https://api.slack.com/quickstart",
  //   "google-docs":
  //     "https://developers.google.com/google-ads/api/docs/get-started/oauth-cloud-project",

  //   "google-sheets":
  //     "https://developers.google.com/google-ads/api/docs/get-started/oauth-cloud-project",
  //   "google-chat":
  //     "https://developers.google.com/google-ads/api/docs/get-started/oauth-cloud-project",
  //   "google-drive":
  //     "https://developers.google.com/google-ads/api/docs/get-started/oauth-cloud-project",
  //   "google-group":
  //     "https://developers.google.com/google-ads/api/docs/get-started/oauth-cloud-project",
  //   openAI: "",
  //   clickup:
  //     "https://help.clickup.com/hc/en-us/articles/6303426241687-Use-the-ClickUp-API",
  // };

  // const howToLink = howToLinks[details.slug] || "";
  return (
    <div className="rounded-2xl border border-[#E2E4E9] bg-white p-4 flex flex-col w-full gap-y-4 overflow-x-scroll lg:overflow-auto">
      <div className="flex w-full items-start justify-between">
        <div className="w-10 h-10 rounded-full border border-[#E1E4EA] flex items-center justify-center">
          <img src={details.icon} />{" "}
        </div>
        <div className="flex flex-row">
          <button onClick={handleGuidePopup}>
            <svg
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 5.25V6.75H8.75V17.25H19.25V13.5H20.75V18C20.75 18.1989 20.671 18.3897 20.5303 18.5303C20.3897 18.671 20.1989 18.75 20 18.75H8C7.80109 18.75 7.61032 18.671 7.46967 18.5303C7.32902 18.3897 7.25 18.1989 7.25 18V6C7.25 5.80109 7.32902 5.61032 7.46967 5.46967C7.61032 5.32902 7.80109 5.25 8 5.25H12.5ZM18.2803 8.78025L14 13.0605L12.9395 12L17.2197 7.71975L14.75 5.25H20.75V11.25L18.2803 8.78025Z"
                fill="#525866"
              />
            </svg>
          </button>
          {details.integrated && (
            <Kebab>
              <div className="min-w-[168px] w-full flex flex-col items-start">
                <button onClick={() => view(details)} className="kebab-button">
                  Manage
                </button>
                <button
                  onClick={handleDisconnect}
                  style={{ color: "#D02533" }}
                  className="kebab-button "
                >
                  Disconnect App
                </button>
              </div>
            </Kebab>
          )}
        </div>
      </div>
      <div className=" flex flex-col overflow-clip">
        <h3 className="font-medium text-[#0A0D14]">{details.name}</h3>
        <p className="text-sm text-[#525866] flex break-all">
          {details.description}
        </p>
      </div>
      {details.integrated ? (
        <span className="mt-auto">
          <Pill status={"connected"} />
        </span>
      ) : (
        <button
          className="flex text-sm items-center gap-x-3 text-[#525866] mt-auto"
          onClick={handlePopup}
        >
          Connect App
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.12925 4.99956L0.416748 1.28706L1.47725 0.226562L6.25025 4.99956L1.47725 9.77256L0.416748 8.71206L4.12925 4.99956Z"
              fill="#525866"
            />
          </svg>
        </button>
      )}
    </div>
  );
};
